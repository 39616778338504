<template>
    <div class="product-features -narrow mt-2">
        <ul>
            <li v-for="experience in tourExperiences" :key="experience">
                <i :class="tourExperienceIconMap.get(`tour-${experience}`) || 'icon-check-ut'"></i>
                {{ experience }}
            </li>
        </ul>
    </div>
</template>


<script setup lang="ts">
    import { PropType } from "vue";

    const tourExperienceIconMap = new Map([
        ["tour-Adventure"                     , "icon-h20-adventure"      ],
        ["tour-Air & Helicopter Tours"        , "icon-Air"                ],
        ["tour-Cruises & Water Tours"         , "icon-Cruises"            ],
        ["tour-Culture, History & Arts"       , "icon-h20-local-immersion"],
        ["tour-Family Vacations"              , "icon-Family"             ],
        ["tour-Festivals & Events"            , "icon-festivals"          ],
        ["tour-Food & Wine"                   , "icon-Food"               ],
        ["tour-Islands & Beaches"             , "icon-h20-beach"          ],
        ["tour-LGBTQ Travel"                  , "icon-lgbtq"              ],
        ["tour-Rail Journeys"                 , "icon-Rail"               ],
        ["tour-Romance, Weddings & Honeymoons", "icon-Romance"            ],
        ["tour-Sightseeing"                   , "icon-Sightseeing"        ],
        ["tour-Sports"                        , "icon-h20-golf"           ],
        ["tour-Walking & Biking"              , "icon-Walking"            ],
        ["tour-Wellness"                      , "icon-h20-wellness-ut"    ],
        ["tour-Wheelchair Accessible"         , "icon-wheelchair"         ],
        ["tour-Wildlife & Nature"             , "icon-Wildlife"           ]
    ]);


    defineProps({
        tourExperiences: {
            type: Array as PropType<string[]>,
            default: undefined
        }
    });
</script>

<template>
    <div :id="'benefit-' + benefit.id" ref="benefit" :class="['-item', (isCarousel) ? 'slide slide-flex' : '']">
        <div v-if="isHostedBenefit" class="-benefit-number-label d-md-none" v-html="hostedBenefitNumberLabel"></div>
        <div class="-overview">
            <img v-if="benefit.thumbnailImageUrl" :src="benefit.thumbnailImageUrl" width="230" class="d-none d-md-block" :title="benefit.name" :alt="benefit.name" />
            <div v-if="benefit.isEvent" :class="['mt-1 text--small -details', (collapseMobileFields) ? 'mobile-benefit-collapsed' : '']">
                <div v-if="benefit.meals">Meals: <b v-html="benefit.meals"></b></div>
                <div v-if="benefit.ageAppropriateness">Recommended&nbsp;Ages: <b v-html="benefit.ageAppropriateness"></b></div>
                <div v-if="benefit.activityLevel">Activity&nbsp;Level: <b v-html="benefit.activityLevel"></b></div>
                <div v-if="benefit.experiences">Experience(s): <b v-html="benefit.experiences"></b></div>
            </div>
        </div>
        <div class="-description">
            <h3 v-html="benefit.name"></h3>
            <div v-if="benefit.location || benefit.duration" class="mt-1">
                <span v-if="benefit.location" v-html="benefit.location"></span>
                <span v-if="benefit.duration" v-html="benefit.duration"></span>
            </div>
            <div v-if="benefit.notice" class="-notice mt-1" v-html="benefit.notice"></div>
            <div v-if="collapseMobileFields" class="d-md-none mobile-benefit-expand-button mt-1 text-center"><button class="btn btn-xs btn-primary-emphasis" @click="expandHostedBenefits">Show all details</button></div>
            <div :class="['mt-1', (collapseMobileFields) ? 'mobile-benefit-collapsed' : '']" v-html="benefit.description"></div>
            <div v-if="benefit.isEvent" :class="['mt-1', (collapseMobileFields) ? 'mobile-benefit-collapsed' : '']">
                <div><b v-html="benefit.availability"></b> of {{ benefit.capacity }} spaces remaining {{ benefit.waitlist }}</div>
            </div>
        </div>
        <div v-if="isHostedBenefit" class="-benefit-number-label d-none d-md-block" v-html="hostedBenefitNumberLabel"></div>
    </div>
</template>


<script setup lang="ts">
    import { CruiseBenefit } from "interfaces/cruise";
    import { getPlural } from "virtuoso-shared-web-ui";
    import { PropType, ref, useTemplateRef } from "vue";

    const props = defineProps({
        benefit: {
            type: Object as PropType<CruiseBenefit>,
            default: undefined
        },
        hostedBenefitIndex: {
            type: Number,
            default: undefined
        },
        isCarousel: {
            type: Boolean
        },
        isHostedBenefit: {
            type: Boolean
        },
        totalHostedBenefits: {
            type: Number,
            default: undefined
        }
    });

    const collapseMobileFields = ref((props.isHostedBenefit && !props.benefit.isForAllGuests));
    const hostedBenefitNumberLabel = ref(`${props.hostedBenefitIndex + 1} of ${props.totalHostedBenefits} possible benefit${getPlural(props.totalHostedBenefits)}`);
    const benefitRef = useTemplateRef("benefit");

    function expandHostedBenefits(): void {
        collapseMobileFields.value = false;

        // Need the carousel to recalculate the height        
        const splideElement = benefitRef.value.closest(`.splide__track`);
        if (splideElement) {
            const evt = new CustomEvent("resize", { bubbles: true, cancelable: false, composed: true });
            splideElement.dispatchEvent(evt);
        }
    }   
</script>

<template>
    <div v-if="isReady" ref="product-detail" class="product-detail">
        <top-splash-component :top-splash-data="topSplashData" @promo-click="showTab('promotions', false, true)"></top-splash-component>
        <div class="container mt-4">
            <div class="product-detail-overview">
                <div class="-info">
                    <h1 class="mb-3 weglot-exclude" v-html="hotel.companyName"></h1>
                    <div v-if="hotel.location" class="-location" v-html="hotel.location"></div>
                    <div v-if="hotel.neighborhood"><b>NEIGHBORHOOD:</b> {{ hotel.neighborhood }}</div>
                    <div v-if="hotel.nearestAirport"><b>NEAREST AIRPORT:</b> {{ hotel.nearestAirport }}</div>
                    <div class="d-md-flex mt-0">
                        <div v-if="hotel.numberOfRooms" class="me-md-3"><b>SIZE:</b> {{ hotel.numberOfRooms }}</div>
                        <div v-if="hotel.roomStyle" class="me-md-3"><b>ROOM STYLE:</b> {{ hotel.roomStyle }}</div>
                        <div v-if="hotel.vibe"><b>VIBE:</b> {{ hotel.vibe }}</div>
                    </div>
                    <hotel-experiences-component class="mt-3" :experiences="hotel.experiences"></hotel-experiences-component>
                    <div class="-description mt-3" v-html="hotel.bobDescription"></div>
                </div>
                <div class="-gallery mt-5 mt-md-0 mb-6">
                    <image-gallery-component v-if="hotel.galleryImages && hotel.galleryImages.length"
                                             :gallery-data="hotel.galleryImages"
                                             :product-id="hotel.id.toString()"
                                             :product-name="hotel.companyName"
                                             :product-type="ProductType.HOTELS"></image-gallery-component>
                    <div v-if="hotel.tip" class="advisor-tip mt-3 mb-0">
                        <h3>Insider Tip</h3>
                        <div class="mt-2" v-html="hotel.tip"></div>
                    </div>
                    <recommended-advisors-component v-if="!suppressRecommendedAdvisors" :advisor-query="recommendedAdvisorQuery" :hide-cobranded-button="true" :set-recommended-advisor-ids="setRecommendedAdvisorIds" :product-type="ProductType.HOTELS"></recommended-advisors-component>
                    <a v-if="!isAdvisorOrVStaff && !isSupplier() && !isEmbeddedMode()" id="b2blink-check-rates-availability" class="btn btn-primary-emphasis btn-sm mt-3 d-block" :href="bookingLink" @click="handleLegacyLinkClick">Check Rates and Availability</a>
                    <a v-if="isSupplier() && !isEmbeddedMode()" id="b2blink-update-your-profile" class="btn btn-primary-emphasis btn-sm mt-3 d-none d-md-block" :href="bookingLinkForAdvisors" @click="handleLegacyLinkClick">For Partners: Update Your Profile</a>
                    <a v-if="isAdvisorOrVStaff && !isEmbeddedMode()" id="b2blink-info-booking" class="btn btn-primary-emphasis btn-sm mt-3 d-block" :href="bookingLinkForAdvisors" @click="handleLegacyLinkClick">For Advisors: Info & Booking</a>
                    <button class="wl-heartable -save-this mt-3" data-wl-type="hotel" :data-wl-id="productId" :data-wl-title="hotel.companyName" :data-wl-list-name="hotel.country" aria-label="Save to Wanderlist"></button>
                </div>
                <div v-if="hotel.amenities" class="-amenities mt-4">
                    <h2 class="text--serif">Virtuoso travelers receive:</h2>
                    <div class="mt-2" v-html="hotel.amenities"></div>
                    <div class="mt-3">Many of these perks and amenities are only available when you book your stay through a Virtuoso travel advisor.</div>
                </div>
            </div>
        </div>
        <hotel-features-component v-if="hotel.hotelFeatures" :hotel-features="hotel.hotelFeatures"></hotel-features-component>
        <div class="container my-6">
            <MapView type="product" :product-map-config="productMapConfig" />
        </div>
        <div v-if="showTabBlock" id="detail-tabs"></div>
        <div v-if="showTabBlock" class="container d-none d-md-block">
            <ul ref="tab-nav-container" class="tab-nav-container">
                <li v-if="hotel.guestRooms.length" id="tab-rooms"><button @click="showTab('rooms')">Guest Rooms &amp; Suites</button></li>
                <li v-if="hotel.promotions.length" id="tab-promotions"><button @click="showTab('promotions')">Promotions &amp; Packages</button></li>
                <li v-if="hotel.healthAndSafety" id="tab-health"><button @click="showTab('health')">Health &amp; Safety Protocols</button></li>
                <li v-if="hotel.reviewsCount > 0" id="tab-reviews"><button @click="showTab('reviews')">Reviews &amp; Recommendations</button></li>
                <li v-if="hasSustainabilityContent" id="tab-sustainability"><button @click="showTab('sustainability')">Sustainability</button></li>
            </ul>
        </div>
        <div v-if="showTabBlock" class="slab -tab-slab">
            <div class="container">
                <ul class="tab-content">
                    <li v-if="hotel.guestRooms.length" id="tc-rooms">
                        <button class="tab-nav" @click="showTab('rooms')">Guest Rooms &amp; Suites</button>
                        <div class="-container">
                            <h4 class="tab-title">Guest Rooms &amp; Suites</h4>
                            <hotel-guest-rooms-component :hotel-name="hotel.companyName" :hotel-id="hotel.id" :rooms="hotel.guestRooms"></hotel-guest-rooms-component>
                        </div>
                    </li>
                    <li v-if="hotel.promotions.length" id="tc-promotions">
                        <button class="tab-nav" @click="showTab('promotions')">Promotions &amp; Packages</button>
                        <div class="-container">
                            <h4 class="-title">Promotions &amp; Packages</h4>
                            <promotions-component :product-type="ProductType.HOTELS" :promotions="hotel.promotions"></promotions-component>
                        </div>
                    </li>
                    <li v-if="hotel.healthAndSafety" id="tc-health">
                        <button class="tab-nav" @click="showTab('health')">Health &amp; Safety Protocols</button>
                        <div class="-container">
                            <h4 class="tab-title">Health &amp; Safety Protocols</h4>
                            <div v-html="hotel.healthAndSafety"></div>
                        </div>
                    </li>
                    <li v-if="hotel.reviewsCount > 0" id="tc-reviews">
                        <button class="tab-nav" @click="showTab('reviews')">Reviews &amp; Recommendations</button>
                        <div class="-container">
                            <h4 class="tab-title">Reviews &amp; Recommendations</h4>
                            <product-reviews-component :company-id="hotel.companyId" :product-type="ProductType.HOTELS" :reviews-data="reviewsData"></product-reviews-component>
                        </div>
                    </li>
                    <li v-if="hasSustainabilityContent" id="tc-sustainability">
                        <button class="tab-nav" @click="showTab('sustainability')">Sustainability</button>
                        <div class="-container">
                            <h4 class="tab-title">Sustainability</h4>
                            <template v-if="hotel.sustainability">
                                <h4 class="fw-bold">Our Commitment</h4>
                                <div class="mb-3" v-html="hotel.sustainability"></div>
                            </template>
                            <template v-if="hotel.sustainabilityCertifications.length > 0">
                                <h4 class="fw-bold">Our Credentials</h4>
                                <ul class="centered-list mb-3">
                                    <li v-for="(cert, index) in hotel.sustainabilityCertifications" :key="index"><a :href="cert.url" target="_blank" v-html="cert.text"></a></li>
                                </ul>
                            </template>
                            <template v-if="hotel.sustainabilityVideoUrl">
                                <video controls playsinline :src="hotel.sustainabilityVideoUrl" width="100%"></video>
                            </template>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <LogoSplash v-else />
</template>


<script setup lang="ts">
    import HotelExperiencesComponent from "components/products/details/hotel-experiences.vue";
    import HotelFeaturesComponent from "components/products/details/hotel-features.vue";
    import HotelGuestRoomsComponent from "components/products/details/hotel-guest-rooms.vue";
    import PromotionsComponent from "components/products/details/product-promotions.vue";
    import ProductReviewsComponent from "components/products/details/product-reviews.vue";
    import RecommendedAdvisorsComponent from "components/advisor/recommended-advisors.vue";
    import ImageGalleryComponent from "components/shared/image-gallery.vue";
    import LogoSplash from "components/shared/logo-splash.vue";
    import MapView from "components/shared/map-view.vue";
    import TopSplashComponent from "components/shared/top-splash.vue";
    import { DotCMSProductDetailPageResponse } from "interfaces/responses/dotcms-responses";
    import { ProductDetailsHotelResponse } from "interfaces/responses/product-detail-responses";
    import { RecommendedAdvisorQuery } from "interfaces/advisor";
    import { ProductType } from "interfaces/enums";
    import { ProductDetailsHotel } from "interfaces/hotel";
    import { GalleryItem } from "interfaces/image";
    import { ProductTopSplash, Promotion } from "interfaces/product";
    import { getCmsContent } from "services/api/cms";
    import { getProductDetails } from "services/api/products";
    import { isAdvisor, isSupplier, isVStaff } from "services/auth/user-info";
    import { formatLocation } from "services/helpers/destinations";
    import { hydrateImageGallery } from "services/helpers/images";
    import { toastError } from "services/helpers/toasts";
    import { isEmbeddedMode, isMobileScreenWidth } from "services/layout/environment";
    import { renderMetadata, setB2BDesktopCookie } from "services/layout/metadata";
    import { getSustainabilityCerts } from "services/transformers/products";
    import { trackEvent } from "services/analytics";
    import { enableHearts } from "services/wanderlist";
    import { cobrandLink, getPlural, parseURLParameters } from "virtuoso-shared-web-ui";
    import { capitalizeFirst } from "virtuoso-shared-web-ui";
    import { nextTick, ref, useTemplateRef } from "vue";

    let topPromoName = ""; // Referenced by GA

    const qsParams = parseURLParameters();

    const props = defineProps({
        productId: {
            type: Number,
            default: undefined
        },
        suppressRecommendedAdvisors: {
            type: Boolean,
            default: false
        }
    });

    const bookingLink = ref("");
    const bookingLinkForAdvisors = ref("");
    const hotel = ref<ProductDetailsHotel>({} as ProductDetailsHotel);
    const hasSustainabilityContent = ref(false);
    const isAdvisorOrVStaff = (isAdvisor() || isVStaff());
    const isReady = ref(false);
    const productDetailRef = useTemplateRef("product-detail");
    const productMapConfig = ref({ 
        popupHtml: "",
        longitude: 0,
        latitude: 0
    });
    const recommendedAdvisorQuery = ref<RecommendedAdvisorQuery>({} as RecommendedAdvisorQuery);
    const reviewsData = ref({});
    const showTabBlock = ref(false);
    const tabNavContainerRef = useTemplateRef("tab-nav-container");
    const topSplashData = ref<ProductTopSplash>({} as ProductTopSplash);

    function handleLegacyLinkClick(): void {
        setB2BDesktopCookie();
        trackEvent("legacy_page_click", {
            affiliation: `${hotel.value.companyId}`,
            item_id: `${props.productId}`,
            item_name: hotel.value.companyName,
            item_category: capitalizeFirst(ProductType.HOTELS.slice(0,-1))
        });
    }

    function loadHotel(): void {
        let resultHotel: ProductDetailsHotelResponse;
        let cmsJSON = {} as DotCMSProductDetailPageResponse;

        const hotelContentPromise = getProductDetails(ProductType.HOTELS, props.productId);
        hotelContentPromise.then((hotelResponse: ProductDetailsHotelResponse) => {
            resultHotel = hotelResponse;
        }); // Reject is caught in the Promise.all below

        const cmsContentPromise = getCmsContent<DotCMSProductDetailPageResponse[]>({
            contentTypes: ["ProductDetailPage"],
            depth: 1,
            limit: 1,
            queryClauses: [
                `+(ProductDetailPage.productId:${props.productId})`
            ]
        });
        cmsContentPromise.then((productJSON) => { // Most products won't have content in dotCMS
            if (productJSON?.length) {
                cmsJSON = productJSON[0];
            }
        }); // Reject is caught in the Promise.all below

        Promise.all([hotelContentPromise, cmsContentPromise]).then(() => {

            if (resultHotel && resultHotel.companyName) {

                const hasAddress = (resultHotel.companyInfo && resultHotel.companyInfo.addresses && resultHotel.companyInfo.addresses.length);

                const thisHotel: ProductDetailsHotel = {
                    address1: (hasAddress && resultHotel.companyInfo.addresses[0].addressLine1) ? resultHotel.companyInfo.addresses[0].addressLine1 : "",
                    address2: (hasAddress && resultHotel.companyInfo.addresses[0].addressLine2) ? resultHotel.companyInfo.addresses[0].addressLine2 : "",
                    address3: (hasAddress && resultHotel.companyInfo.addresses[0].addressLine3) ? resultHotel.companyInfo.addresses[0].addressLine3 : "",
                    amenities: resultHotel.virtuosoAmenitiesHtml,
                    bobDescription: resultHotel.asSeenInTravelFolioDescription,
                    companyId: resultHotel.companyId || 0, // Only used for retrieving reviews
                    country: resultHotel.companyInfo.addresses[0].country,
                    experiences: (resultHotel.hotelExperiences && resultHotel.hotelExperiences.length) ? resultHotel.hotelExperiences : [],
                    featuredImageUrl: "https://virtuoso-prod.dotcms.cloud/images/image-not-available-results-266x200.png", // fallback, replaced later
                    guestRooms: (resultHotel.guestRooms) ? resultHotel.guestRooms : [],
                    healthAndSafety: (resultHotel.supplierHealthAndSafetyHtml) ? resultHotel.supplierHealthAndSafetyHtml : "",
                    hotelFeatures: {
                        features: (resultHotel.hotelFeatures.features && resultHotel.hotelFeatures.features.length) ? resultHotel.hotelFeatures.features : [],
                        inYourRoom: (resultHotel.hotelFeatures.inYourRoom && resultHotel.hotelFeatures.inYourRoom.length) ? resultHotel.hotelFeatures.inYourRoom : [],
                        recreation: (resultHotel.hotelFeatures.recreation && resultHotel.hotelFeatures.recreation.length) ? resultHotel.hotelFeatures.recreation : [],
                        services: (resultHotel.hotelFeatures.services && resultHotel.hotelFeatures.services.length) ? resultHotel.hotelFeatures.services : []
                    },
                    id: props.productId,
                    latitude: (resultHotel.latitude) ? resultHotel.latitude : 0,
                    longitude: (resultHotel.longitude) ? resultHotel.longitude : 0,
                    companyName: cmsJSON.title || resultHotel.companyName,
                    neighborhood: (resultHotel.neighborhood) ? resultHotel.neighborhood : "",
                    numberOfRooms: (resultHotel.numberOfRooms) ? `${resultHotel.numberOfRooms} room${getPlural(resultHotel.numberOfRooms)}` : "",
                    promotions: [],
                    roomStyle: (resultHotel.roomStyle) ? resultHotel.roomStyle : "",
                    sustainability: (resultHotel.supplierSustainability) ? resultHotel.supplierSustainability : "",
                    sustainabilityCertifications: getSustainabilityCerts(resultHotel.sustainabilityCertifications),
                    sustainabilityVideoUrl: resultHotel.sustainabilityVideoUrl,
                    tip: (resultHotel.asSeenInTravelFolioInTheKnow) ? resultHotel.asSeenInTravelFolioInTheKnow : "",
                    vibe: (resultHotel.hotelVibes) ? resultHotel.hotelVibes : ""
                };

                // Location
                if (hasAddress) {
                    thisHotel.location = formatLocation(resultHotel.companyInfo.addresses[0].city, resultHotel.companyInfo.addresses[0].state, resultHotel.companyInfo.addresses[0].country);
                }

                // Images -- first image is the featured image
                let galleryImages: GalleryItem[] = [];
                if (resultHotel.imageLibraryItems && resultHotel.imageLibraryItems.length) {
                    galleryImages = hydrateImageGallery(resultHotel.imageLibraryItems);
                    thisHotel.featuredImageUrl = galleryImages[0].image;
                    thisHotel.featuredImageCaption = galleryImages[0].description;
                }
                thisHotel.galleryImages = galleryImages;

                // Featured Video
                if (resultHotel.supplierVideos && resultHotel.supplierVideos.length) {
                    const featuredVideo = resultHotel.supplierVideos.find((video) => video.isFeaturedVideo);
                    if (featuredVideo) {
                        thisHotel.featuredVideoCaption = featuredVideo.title;
                        thisHotel.featuredVideoUrl = featuredVideo.webContentURL;
                    }
                }

                // Nearest airport
                if (resultHotel.nearestAirportDescription) {
                    thisHotel.nearestAirport = resultHotel.nearestAirportDescription;
                    if (resultHotel.nearestAirportDistanceInMiles) {
                        thisHotel.nearestAirport += ` - ${resultHotel.nearestAirportDistanceInMiles} mi/${Math.round(resultHotel.nearestAirportDistanceInMiles * 1.609)} km`;
                    }
                }

                // Promotions -- Virtuoso Exclusive Promotions added first
                const combinedPromotions: Promotion[] = [];
                const sourcePromotionsExclusives = (resultHotel.virtuosoExclusivePromotions && resultHotel.virtuosoExclusivePromotions.length) ? resultHotel.virtuosoExclusivePromotions : [];
                const sourcePromotionsNormal = (resultHotel.promotions && resultHotel.promotions.length) ? resultHotel.promotions : [];
                [...sourcePromotionsExclusives, ...sourcePromotionsNormal].forEach((promo) => {
                    if (!promo.isAdvisorIncentive && !promo.incentiveTypeCode) {
                        combinedPromotions.push({
                            description: promo.description || "",
                            endDateMS: (promo.travelEndDate) ? new Date(promo.travelEndDate).getTime() : 0,
                            formattedTravelDates: promo.formattedTravelDates || "",
                            isExclusive: (promo.promotionType === "Virtuoso Exclusive Hotel Offer"),
                            name: promo.promotionName || "",
                            promotionId: promo.masterEntityId,
                            startDateMS: (promo.travelStartDate) ? new Date(promo.travelStartDate).getTime() : 0,
                            url: (promo.masterEntityId) ? cobrandLink(`/${(promo.promotionType === "Virtuoso Exclusive Hotel Offer") ? "virtuosoexclusivepromotions" : "promotions"}/${promo.masterEntityId}`) : ""
                        });
                    }
                });

                // Check for featured promotion and reorder the array if necessary
                if (cmsJSON.featuredPromotionId && combinedPromotions.length > 1) {
                    const featuredPromoIndex = combinedPromotions.findIndex((promo) => parseInt(cmsJSON.featuredPromotionId, 10) === promo.promotionId);
                    if (featuredPromoIndex > 0) {
                        const featuredItem = combinedPromotions.splice(featuredPromoIndex, 1);
                        combinedPromotions.unshift(featuredItem[0]);
                    }
                }

                thisHotel.promotions = combinedPromotions;
                topPromoName = (thisHotel.promotions.length) ? thisHotel.promotions[0].name : "";
                hasSustainabilityContent.value = !!(thisHotel.sustainability || thisHotel.sustainabilityCertifications.length || thisHotel.sustainabilityVideoUrl);


                // Reviews metadata -- actual reviews are pulled in separate component
                const reviewsObj = (resultHotel.reviewsInfoJson) ? JSON.parse(resultHotel.reviewsInfoJson) : {};
                thisHotel.reviewsCount = (reviewsObj && reviewsObj.TotalActiveReviews) ? reviewsObj.TotalActiveReviews : 0;
                thisHotel.reviewsPercent = (reviewsObj && reviewsObj.TotalRecommendedPercent) ? Math.round(reviewsObj.TotalRecommendedPercent) : 100;
                if (thisHotel.reviewsCount > 0) {
                    // passed to reviews component
                    reviewsData.value = {
                        percent: `<b>${thisHotel.reviewsPercent}% Recommended</b>`,
                        count: `<b>${thisHotel.reviewsCount} Review${getPlural(thisHotel.reviewsCount)}</b>`
                    };
                }

                // Build map popup HTML
                let mapPopupHtml = `<h3>${thisHotel.companyName}</h3>`;
                if (hasAddress) {
                    mapPopupHtml += (thisHotel.address1) ? `${thisHotel.address1}<br>` : "";
                    mapPopupHtml += (thisHotel.address2) ? `${thisHotel.address2}<br>` : "";
                    mapPopupHtml += (thisHotel.address3) ? `${thisHotel.address3}<br>` : "";
                    mapPopupHtml += (thisHotel.location) ? thisHotel.location : "";
                }
                productMapConfig.value = {
                    popupHtml: mapPopupHtml,
                    latitude: thisHotel.latitude,
                    longitude: thisHotel.longitude
                };

                bookingLink.value = cobrandLink(`/hotels/${thisHotel.id}?flow=1`);
                bookingLinkForAdvisors.value = cobrandLink(`/hotels/${thisHotel.id}`);
                hotel.value = thisHotel;

                topSplashData.value = {
                    ...(thisHotel.promotions.length && { promotion: thisHotel.promotions[0] }), // set Promotion if it exists
                    companyName: thisHotel.companyName,
                    featuredImageCaption: thisHotel.featuredImageCaption,
                    featuredImageUrl: thisHotel.featuredImageUrl,
                    featuredVideoUrl: thisHotel.featuredVideoUrl,
                    productName: thisHotel.companyName,
                    productType: ProductType.HOTELS,
                    wanderlistId: props.productId.toString(),
                    wanderlistName: thisHotel.country
                } as ProductTopSplash;

                renderMetadata({
                    description: thisHotel.bobDescription,
                    title: thisHotel.companyName
                });


                // Recommended Advisors
                recommendedAdvisorQuery.value = {
                    Id: thisHotel.id,
                    InterestType: "",
                    ProductLocationCountry: (hasAddress) ? resultHotel.companyInfo.addresses[0].country : "",
                    ProductPois: "",
                    ProductTypeName: "Hotel"
                } as RecommendedAdvisorQuery;

                isReady.value = true;
               


                nextTick(() => {
                    // Onload tab support for this hotel
                    showThenJumpToTab();
                    enableHearts(productDetailRef.value);
                });

            } else {
                redirectOnError();
            }
        }, () => redirectOnError());
    }

    function redirectOnError(): void {
        toastError("Error retrieving data");
        setTimeout(() => { location.href = cobrandLink(`/travel/luxury-hotels`); }, 3000);
    }

    function setRecommendedAdvisorIds(advisor1MEID?: number, advisor2MEID?: number): void {
        // get hotel index key in sessionStorage
        const getVirtuosoHotelSearchIndexValue = sessionStorage.getItem(`VirtuosoHotelSearchIndex_${props.productId}`);

        if (getVirtuosoHotelSearchIndexValue) {
            // then delete hotel index key in sessionStorage
            sessionStorage.removeItem(`VirtuosoHotelSearchIndex_${props.productId}`);
        }

        trackEvent("view_item", {
            ...((getVirtuosoHotelSearchIndexValue) && { index: parseInt(getVirtuosoHotelSearchIndexValue, 10) }), // should add index with value if session key existed
            item_id: `${props.productId}`,
            item_name: hotel.value.companyName,
            coupon: (hotel.value.promotions.length >= 1) ? "Promotion Available" : "",
            item_category: "Hotel",
            item_category2: `${hotel.value.reviewsCount}`,
            item_category3: hotel.value.vibe,
            item_category4: (advisor1MEID) ? `${advisor1MEID}` : "",
            item_category5: (advisor2MEID) ? `${advisor2MEID}` : "",
            item_variant: hotel.value.roomStyle
        });
    }

    function scrollToTabResponsive(tabName = ""): void {
        if (isMobileScreenWidth()) {
            return (tabName) ? document.getElementById(`tc-${tabName}`)?.scrollIntoView()
                : document.querySelector(".tab-content .-active")?.scrollIntoView({ block: "start" });
        }
        tabNavContainerRef.value.scrollIntoView();
    }

    function showTab(tabName: string, preventJump = false, fromTopLink = false): void {
        document.querySelectorAll(".tab-nav-container .-active, .tab-content .-active").forEach((el) => el.classList.remove("-active"));
        document.querySelectorAll(`#tab-${tabName}, #tc-${tabName}`).forEach((el) => el.classList.add("-active"));

        if (!preventJump) {
            scrollToTabResponsive(tabName);
        }

        if (fromTopLink) {
            trackEvent("view_promotion", {
                item_id: `${hotel.value.promotions[0].promotionId}`,
                item_name: topPromoName,
                item_category: "Promotion",
                item_variant: (hotel.value.promotions[0].isExclusive) ? "Virtuoso Exclusive Hotel Offer" : "Hotel/Resort",
                affiliation: `${props.productId}`
            });
        }
    }

    function showThenJumpToTab(): void {
        const defaultTabName =
            (hotel.value.guestRooms.length) ? "rooms" :
            (hotel.value.promotions.length) ? "promotions" :
            (hotel.value.healthAndSafety) ? "health" :
            (hotel.value.reviewsCount > 0) ? "reviews" :
            (hasSustainabilityContent.value) ? "sustainability" : "";

        const qsTabLabel = qsParams["tab"];

        showTabBlock.value = (defaultTabName.length > 0);

        nextTick(() => { // Tab Show then Scroll
            // Show
            if (defaultTabName) { // first tab
                showTab(defaultTabName, true);
            }
            if (qsTabLabel && document.getElementById(`tc-${qsTabLabel}`)) { // check qsParam tab exists
                showTab(qsTabLabel, true);
            }
            // Show Promo Manual Tab
            if (qsParams.promotions === "1") { // If the manually added ?promotions=1 query string variable is present, scroll to the promotions section on load
                const qsPromoTabName = (hotel.value.promotions.length) ? "promotions" : defaultTabName;
                showTab(qsPromoTabName, true);
            }
            // Default Scroll
            if (("tab" in qsParams) || ("promotions" in qsParams)) {
                scrollToTabResponsive();
            }
        });
    }

    loadHotel();
    
</script>

<template>
    <div class="slab product-features mt-6">
        <div class="container">
            <h2 class="text--serif">At the Hotel</h2>
            <template v-for="hotelFeatureKey in Object.keys(hotelFeatures)">
                <div v-if="hotelFeatures[hotelFeatureKey as keyof ProductDetailHotelFeatures].length" :key="hotelFeatureKey" class="-category">
                    <h4>{{ hotelFeatureCategoryMap.get(hotelFeatureKey) }}</h4>
                    <ul>
                        <li v-for="feature in hotelFeatures[hotelFeatureKey as keyof ProductDetailHotelFeatures]" :key="feature">
                            <i :class="hotelFeatureIconMap.get(`${hotelFeatureKey}-${feature}`) || 'icon-check-ut'"></i>
                            {{ feature }}
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </div>
</template>


<script setup lang="ts">
    import { ProductDetailHotelFeatures } from "interfaces/responses/product-detail-responses";
    import { PropType } from "vue";

    defineProps({
        hotelFeatures: {
            type: Object as PropType<ProductDetailHotelFeatures>,
            default: () => ({})
        }
    });

    const hotelFeatureCategoryMap = new Map<string, string>([
        ["features"     , "Features"    ],
        ["inYourRoom"   , "In Your Room"],
        ["recreation"   , "Recreation"  ],
        ["services"     , "Services"    ]
    ]);

    const hotelFeatureIconMap = new Map<string, string>([
        ["features-24-hour Security"               , "icon-lock"            ],
        ["features-Adults Only"                    , "icon-adult"           ],
        ["features-Air Conditioning"               , "icon-air-conditioner" ],
        ["features-All-inclusive"                  , "icon-all-inclusive"   ],
        ["features-Banquet Facilities"             , "icon-banquet"         ],
        ["features-Business Center"                , "icon-printer"         ],
        ["features-Casino"                         , "icon-coins"           ],
        ["features-Children's Programs"            , "icon-children"        ],
        ["features-Club Floor"                     , "icon-disco"           ],
        ["features-Complimentary Parking"          , "icon-parking"         ],
        ["features-Conference Facilities"          , "icon-networking"      ],
        ["features-Connecting Rooms"               , "icon-connected-rooms" ],
        ["features-Culinary Program"               , "icon-culinary"        ],
        ["features-Internet Access"                , "icon-connection"      ],
        ["features-Library"                        , "icon-library"         ],
        ["features-Lounge/Bar"                     , "icon-lounge"          ],
        ["features-Meal Plans Available"           , "icon-meal-plans"      ],
        ["features-Michelin Star Restaurant"       , "icon-michelin"        ],
        ["features-Multi-lingual Staff"            , "icon-multi-lingual"   ],
        ["features-Nightclub"                      , "icon-disco"           ],
        ["features-Parking"                        , "icon-parking"         ],
        ["features-Pet Friendly"                   , "icon-paw"             ],
        ["features-Restaurant"                     , "icon-restaurant"      ],
        ["features-Shopping"                       , "icon-shopping"        ],
        ["features-Villas"                         , "icon-villa"           ],
        ["features-Wheelchair Accessible"          , "icon-wheelchair"      ],
        ["inYourRoom-Bathrobes"                    , "icon-bathrobe"        ],
        ["inYourRoom-Butler Service"               , "icon-butler"          ],
        ["inYourRoom-Complimentary Coffee/Tea"     , "icon-tea"             ],
        ["inYourRoom-Complimentary Newspaper"      , "icon-newspaper"       ],
        ["inYourRoom-Hair Dryer"                   , "icon-hair-dryer"      ],
        ["inYourRoom-Iron/Ironing Board"           , "icon-iron"            ],
        ["inYourRoom-Mini Bar"                     , "icon-mini-bar"        ],
        ["inYourRoom-Safe"                         , "icon-safe"            ],
        ["inYourRoom-Slippers"                     , "icon-slippers"        ],
        ["recreation-Bicycle Rental"               , "icon-bicycle"         ],
        ["recreation-Fitness Center"               , "icon-fitness"         ],
        ["recreation-Golf"                         , "icon-golf"            ],
        ["recreation-Horseback Riding"             , "icon-horseback-riding"],
        ["recreation-Jacuzzi/Whirlpool"            , "icon-jacuzzi1"        ],
        ["recreation-Pool"                         , "icon-pool"            ],
        ["recreation-Sauna"                        , "icon-sauna"           ],
        ["recreation-Skiing"                       , "icon-skiing"          ],
        ["recreation-Snow Sports"                  , "icon-snow-sports"     ],
        ["recreation-Spa"                          , "icon-spa"             ],
        ["recreation-Tennis Courts"                , "icon-tennis"          ],
        ["recreation-Water Sports"                 , "icon-water-sports"    ],
        ["services-Babysitting Services"           , "icon-babysitting"     ],
        ["services-Car Rental Desk"                , "icon-car"             ],
        ["services-Complimentary Airport Transfers", "icon-shuttle"         ],
        ["services-Concierge Desk"                 , "icon-concierge"       ],
        ["services-Currency Exchange"              , "icon-exchange"        ],
        ["services-Hearing Impaired Services"      , "icon-hearing-impaired"],
        ["services-House Safe"                     , "icon-house-safe"      ],
        ["services-Housekeeping -- Twice Daily"    , "icon-housekeeping"    ],
        ["services-Laundry/Dry Cleaning/Pressing"  , "icon-laundry"         ],
        ["services-Limousine Service"              , "icon-limo"            ],
        ["services-Room Service"                   , "icon-room-service"    ],
        ["services-Room Service 24-hours"          , "icon-room-service"    ],
        ["services-Salon"                          , "icon-salon"           ],
        ["services-Shoeshine Service"              , "icon-shoe"            ],
        ["services-Turndown Service"               , "icon-turndown"        ],
        ["services-Valet Parking"                  , "icon-valet"           ],
        ["services-Wakeup Calls"                   , "icon-wakeup-call"     ],
        ["services-Wedding Services"               , "icon-wedding"         ]
    ]);
</script>

<template>
    <div v-if="canViewComponent" id="track-sailing-component" class="mt-2">
        <button v-show="!showTrackCruiseForm" class="btn btn-primary-regular btn-sm w-100" @click="toggleTrackSailingForm()">Track Sailing</button>
        <div v-show="showTrackCruiseForm" class="-wrapper">
            <h3>Track this Sailing</h3>
            <div class="mt-1 text--small">Client Name(s)</div>
            <div class="mt-half input-group">
                <input id="track-sailing-client" v-model="clientNamesValue" type="text" maxlength="50" class="form-control" :disabled="isLoading" @keyup.enter="doTrackCruise" />
            </div>
            <div v-if="validationText" class="text-danger text--small mt-1">{{ validationText }}</div>
            <div class="mt-1">
                <button :class="['btn btn-primary-emphasis btn-sm', isLoading ? 'disabled' : '']" :disabled="isLoading" @click="doTrackCruise">Save</button>
                <button :class="['btn btn-tertiary btn-sm ms-2', isLoading ? 'disabled' : '']" :disabled="isLoading" @click="toggleTrackSailingForm(true)">Cancel</button>
            </div>
        </div>
        <div class="text--small mt-2 mx-auto d-none d-lg-block"><a :href="cobrandLink('/cruisealerts')">Learn more about Virtuoso Cruise Alerts</a></div>
    </div>
</template>


<script setup lang="ts">
    import { AddCruiseAlertResponse } from "interfaces/responses/product-detail-responses";
    import { saveTrackSailing } from "services/api/user";
    import { isAdvisor, isVStaff } from "services/auth/user-info";
    import { toastError, toastSuccess } from "services/helpers/toasts";
    import { isEmbeddedMode } from "services/layout/environment";
    import { cobrandLink } from "virtuoso-shared-web-ui";
    import { ref } from "vue";

    const props = defineProps({
        sailingId: {
            type: Number,
            default: undefined
        }
    });

    const clientNamesValue = defineModel<string>();
    const canViewComponent = (isAdvisor() || isVStaff()) && !isEmbeddedMode();
    const showTrackCruiseForm = ref(false);
    const validationText = ref("");
    const isLoading = ref(false);

        
    function doTrackCruise(): void {
        if (!clientNamesValue.value || clientNamesValue.value.length < 3 || clientNamesValue.value.length > 50) {
            validationText.value = "Client name(s) must be between 3 and 50 characters.";
            return;
        }

        isLoading.value = true;
        validationText.value = "";

        saveTrackSailing(props.sailingId, clientNamesValue.value).then((resultJSON: AddCruiseAlertResponse) => {
                    if (resultJSON && resultJSON.success) {
                        toastSuccess("Sailing tracked successfully!");
                        toggleTrackSailingForm();
                        clientNamesValue.value = "";
                        isLoading.value = false;
                    } else {
                        toastError("Error tracking this sailing, please try again.");
                        isLoading.value = false;
                    }
                }, () => {
                    toastError("Error tracking this sailing, please try again.");
                    isLoading.value = false;
                });
    }

    function toggleTrackSailingForm(clearValue = false): void {
        validationText.value = "";
        if (clearValue) {
            clientNamesValue.value = "";
        }

        showTrackCruiseForm.value = !showTrackCruiseForm.value;
    }
</script>

/**
 * applyVerticalTruncation - Sets truncation with a gradient and "View more" link on the bottom
 *   The whole element is clickable to expand.
 *   Height of the truncated content is based off of the max-height of .truncate-vertical.-initialized,
        which defaults to the values in .truncate-5
 *   Use the @truncate-lines mixin to set different heights.
 *   Optionally add a data-view-more-text element to specify link text other than "View more".
 * @param containerElement - The block element(s) to be vertically truncated, defaults to document.querySelectorAll(".truncate-vertical")
 */
export function applyVerticalTruncation(truncateElements: NodeList = document.querySelectorAll<HTMLElement>(".truncate-vertical")): void {
    truncateElements.forEach((element: HTMLElement) => {

        if (!element.classList.contains("-initialized")) {

            const viewMoreText: string = element.dataset.viewMoreText || "View more";
            const viewLessText: string = element.dataset.viewLessText || "View less";

            element.classList.add("-initialized");

            const initialHeight: number = element.offsetHeight;
            const copyHeight = element.scrollHeight;

            // Check to see if the text is long enough to even worry with this mess
            if (copyHeight <= initialHeight) {
                element.classList.add("-not-needed");
            } else {

                // Add the inner div
                const innerWrap = document.createElement("div");
                innerWrap.classList.add("-inner");
                innerWrap.innerHTML = element.innerHTML;

                // Add the 'View less" button now so it takes up space when expanding
                innerWrap.insertAdjacentHTML("beforeend", `<div><button class="btn-link -toggle -view-less">${viewLessText} <i class="icon-angle-up"></i></button></div>`);
                element.innerHTML = innerWrap.outerHTML;
                const maxHeight = element.scrollHeight;

                // Add the gradient overlay
                element.insertAdjacentHTML("beforeend", `<div class="-gradient"><button class="btn-link -toggle">${viewMoreText} <i class="icon-angle-down"></i></button></div>`);

                // // Allow re-collapsing
                element.querySelector(".-view-less").addEventListener("click", () => {
                    element.style.maxHeight = maxHeight + "px"; // Explicitly reset the current full height from 'none'
                    setTimeout(() => { // Let the max-height percolate so the transition transitions
                        element.style.maxHeight = initialHeight + "px"; // Then back down to collapsed
                    }, 1);

                    // Re-enable "View more"
                    setTimeout(() => { // Prevent immediate re-execution
                        applyTruncationHandlers(element, maxHeight);
                        element.classList.remove("-reveal");
                    }, 100);
                });

                applyTruncationHandlers(element, maxHeight);
            }
        }
    });

}

function applyTruncationHandlers(el: HTMLElement, maxHeight: number): void {
    el.addEventListener("click", () => {
        el.style.maxHeight = maxHeight + "px";

        function handleTransitionEnd(e: Event) {
            if (e.target === e.currentTarget) {
                // To keep multiple events from being fired (child elements with transitions)
                el.style.maxHeight = "none"; // Set the max-height to none after the transition so page resizing works
                el.removeEventListener("transitionend", handleTransitionEnd);
            }
        }

        el.addEventListener("transitionend", handleTransitionEnd);

        el.classList.add("-reveal");
    }, { once: true });
}

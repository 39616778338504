<template>
    <div v-if="isReady" class="product-detail -advisor">
        <div class="container px-lg-0">
            <div class="advisor-header context-dark">
                <div class="-photo">
                    <img :src="advisor.featuredImageUrl" :title="'Photo of ' + advisor.advisorName" :alt="'Photo of ' + advisor.advisorName">
                </div>
                <div class="-info">
                    <h1 class="mb-2">{{ advisor.advisorFirstName }}<br />{{ advisor.advisorLastName }}</h1>
                    <div class="-agency">{{ advisor.icPrefix }}<a :href="advisor.agencyUrl" v-html="advisor.companyName"></a></div>
                    <div class="-location" v-html="advisor.location"></div>
                </div>
                <div class="-links pt-2 pt-md-1">
                    <ul v-if="advisor.socialMedia.length" class="list-unstyled list-inline mb-0">
                        <li v-for="(website, index) in advisor.socialMedia" :key="index"><a :href="website.siteUrl" class="text-decoration-none" target="_blank" rel="external"><i :class="socialMediaIconMap.get(website.siteName)"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="sticky-advisor-header bg--white justify-content-sm-center">
                <div class="-info context-dark">
                    <div class="h2" v-html="advisor.advisorName"></div>
                </div>
                <div class="d-flex flex-row justify-content-lg-between align-items-center my-2">
                    <div class="d-none d-xl-block">
                        <ul class="tab-nav-container">
                            <li id="tab-about" class="-active"><button @click="jumpToSection('about')">About Me</button></li>
                            <li v-if="placesVisitedMapConfig.mapMarkers.length" id="tab-places"><button @click="jumpToSection('places')">Places Visited</button></li>
                            <li v-if="advisor.reviewsCount > 0" id="tab-reviews"><button @click="jumpToSection('reviews')">Reviews &amp; Recommendations</button></li>
                        </ul>
                    </div>
                    <div v-if="advisor.isAcceptingNewClients" class="-contact-button">
                        <advisor-contact-button-component class="-inline" :advisor="advisorContactButtonInfo(advisor)" event-name="Advisor Detail" button-style="sub-head"></advisor-contact-button-component>
                    </div>
                </div>
            </div>
            <section id="about">
                <div class="product-detail-overview">
                    <div class="-info">
                        <div v-if="advisor.companyLogoUrl" class="d-block d-md-none my-3 text-center -logo">
                            <a :href="advisor.agencyUrl"><img :src="advisor.companyLogoUrl" :title="advisor.icPrefix + advisor.companyName" :alt="advisor.icPrefix + advisor.companyName" /></a>
                        </div>
                        <h2 class="text--serif fw-normal">About Me</h2>
                        <div class="-description mt-3" v-html="advisor.description"></div>
                    </div>
                    <div class="-gallery mt-5 mt-md-0 mb-6">
                        <div v-if="advisor.companyLogoUrl" class="d-none d-md-block mb-4 text-center -logo">
                            <a :href="advisor.agencyUrl"><img :src="advisor.companyLogoUrl" :title="advisor.icPrefix + advisor.companyName" :alt="advisor.icPrefix + advisor.companyName" /></a>
                        </div>
                        <div v-if="advisor.interests.length || advisor.destinations.length">
                            <h4 class="mb-2">Travel Specialties</h4>
                            <div class="row mb-3">
                                <div v-if="advisor.interests.length" class="col-6">
                                    <b>Interests</b><br />
                                    <ul class="list-unstyled mt-half">
                                        <li v-for="(interest, index) in advisor.interests" :key="index" v-html="interest"></li>
                                    </ul>
                                </div>
                                <div v-if="advisor.destinations.length" class="col-6">
                                    <b>Destinations</b><br />
                                    <ul class="list-unstyled mt-half">
                                        <li v-for="(destination, destIndex) in advisor.destinations" :key="destination" :class="[(destIndex > 4 && !showExtraDestinations) ? 'd-none' : '']"
                                            v-html="destination"></li>
                                        <li v-if="advisor.destinations.length > 5 && !showExtraDestinations"><button class="btn btn-link btn-sm p-0" @click="showExtraDestinations = true">View All</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <template v-if="languagesCSV">
                            <h4>Languages</h4>
                            <div class="mb-3" v-html="languagesCSV"></div>
                        </template>
                        <template v-if="advisor.sellingTravelSince">
                            <h4>Planning Travel Since:</h4>
                            <div class="mb-3" v-html="advisor.sellingTravelSince"></div>
                        </template>
                        <div v-if="advisor.isVVCertifiedHost" class="text-center">
                            <img src="https://virtuoso-prod.dotcms.cloud/images/products/vv-certified-host.png" title="Virtuoso Voyages Certified Host" alt="Virtuoso Voyages Certified Host" width="200" height="118" />
                        </div>
                        <a v-if="isNetworkUser" class="btn btn-primary-emphasis btn-sm d-block mt-3" :href="cobrandLink(`/advisors/${props.productId}`)" @click="handleLegacyLinkClick">{{ (isAdvisor()) ? "For Advisors: Update Your Profile" : "For Partners: Advanced Results" }}</a>
                    </div>
                </div>
            </section>
            <section v-if="placesVisitedMapConfig.mapMarkers.length" id="places">
                <h2 class="text--serif fw-normal mb-3 mb-xl-0">Places Visited</h2>
                <table class="largeMapTableContainer mx-auto">
                    <tbody>
                        <tr>
                            <td class="largeMapCell">
                                <MapView type="placesVisited" :places-visited-map-config="placesVisitedMapConfig" />
                            </td>
                            <td class="largeMapListCell">
                                <div class="largeMapListDiv">
                                    <table class="largeMapListTable f16"></table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section v-if="advisor.reviewsCount > 0" id="reviews">
                <h2 class="text--serif fw-normal mb-3">Reviews &amp; Recommendations</h2>
                <product-reviews-component v-if="!suppressReviews" :company-id="advisor.id" :product-type="ProductType.ADVISORS" :reviews-data="reviewsData"></product-reviews-component>
            </section>
        </div>
    </div>
    <LogoSplash v-else />
</template>


<script setup lang="ts">
    import ProductReviewsComponent from "components/products/details/product-reviews.vue";
    import AdvisorContactButtonComponent from "components/advisor/advisor-contact-button.vue";
    import LogoSplash from "components/shared/logo-splash.vue";
    import MapView from "components/shared/map-view.vue";
    import { AdvisorVisitedPlace, ProductDetailsAdvisorResponse } from "interfaces/responses/product-detail-responses";
    import { AdvisorContactButton } from "interfaces/advisor";
    import { ProductType } from "interfaces/enums";
    import { MapMarker } from "interfaces/map";
    import { ProductDetailsAdvisor } from "interfaces/product";
    import { getProductDetails } from "services/api/products";
    import { isAdvisor, isNetworkUser, virtuosoUser } from "services/auth/user-info";
    import { formatLocation } from "services/helpers/destinations";
    import { generateMediaServerImageUrl } from "services/helpers/images";
    import { toastError } from "services/helpers/toasts";
    import { renderMetadata } from "services/layout/metadata";
    import { initAdvisorSubhead } from "services/layout/sticky-subhead";
    import { formatAdvisorAgencyName } from "services/transformers/products";
    import { trackEvent } from "services/analytics";
    import { capitalizeFirst, cobrandLink, getCobrandType, getPlural, slugify } from "virtuoso-shared-web-ui";
    import { nextTick, ref } from "vue";


    const props = defineProps({
        productId: {
            type: Number,
            default: undefined
        },
        suppressReviews: {
            type: Boolean,
            default: false
        }
    });

    const advisor = ref<ProductDetailsAdvisor>({} as ProductDetailsAdvisor);
    const isReady = ref(false);
    const languagesCSV = ref("");
    const reviewsData = ref({});
    const showExtraDestinations = ref(false);
    const placesVisitedMapConfig = ref({ mapMarkers: [] as MapMarker[]});

    const socialMediaIconMap = new Map([
        ["Facebook", "icon-facebook-circle"],
        ["Instagram", "icon-instagram-circle"],
        ["LinkedIn", "icon-linkedin-circle"],
        ["Pinterest", "icon-pinterest-circle"],
        ["Twitter", "icon-twitter-circle"],
        ["YouTube", "icon-youtube-circle"],
        ["Facebook", "icon-facebook-circle"],
        ["Facebook", "icon-facebook-circle"],
        ["Facebook", "icon-facebook-circle"]
    ]);

    if (getCobrandType() === "advisor" && virtuosoUser.cobrandModel.advisorMeid !== props.productId) {
        location.href = cobrandLink(`/travel`);
    } else {
        loadAdvisor();
    }

    function advisorContactButtonInfo(advisor: ProductDetailsAdvisor): AdvisorContactButton {
        return {
            company: advisor.companyName,
            companyId: advisor.companyId,
            country: advisor.agencyCountryName,
            email: advisor.advisorEmail,
            id: advisor.id,
            name: advisor.advisorName,
            phone: advisor.advisorPhone,
            productType: ProductType.ADVISORS
        };
    }

    function handleLegacyLinkClick(): void {
        trackEvent("legacy_page_click", {
            affiliation: `${advisor.value.companyId}`,
            item_id: `${props.productId}`,
            item_name: advisor.value.advisorName,
            item_category: capitalizeFirst(ProductType.ADVISORS.slice(0,-1))
        });
    }

    function jumpToSection(sectionName: string): void {
        document.querySelector(".tab-nav-container .-active").classList.remove("-active");
        document.getElementById(`tab-${sectionName}`).classList.add("-active");

        document.getElementById(sectionName)?.scrollIntoView();
        setTimeout(function () {
            document.getElementById(sectionName)?.scrollIntoView(); // In case the sticky header appearing/disappearing caused a shift
        }, 1);
    }

    function loadAdvisor(): void {
        getProductDetails(ProductType.ADVISORS, props.productId).then((resultAdvisor: ProductDetailsAdvisorResponse) => {

            if (resultAdvisor && resultAdvisor.advisorName) {
                const thisAdvisor: ProductDetailsAdvisor = {
                    advisorEmail: resultAdvisor.advisorEmail,
                    advisorFirstName: resultAdvisor.advisorFirstName,
                    advisorLastName: resultAdvisor.advisorLastName,
                    advisorName: resultAdvisor.advisorName,
                    advisorPhone: resultAdvisor.officePhone,
                    agencyCountryName: resultAdvisor.agencyCountryName,
                    agencyUrl: cobrandLink(`/agencies/${resultAdvisor.companyId}/${slugify(resultAdvisor.companyName)}`),
                    companyId: resultAdvisor.companyId,
                    companyLogoUrl: resultAdvisor.companyLogo,
                    companyName: resultAdvisor.companyName,
                    dbaName: resultAdvisor.advisorDoingBusinessAs,
                    description: resultAdvisor.advisorAboutMe,
                    destinations: resultAdvisor.advisorSpecialtyCountries || [],
                    featuredImageUrl: generateMediaServerImageUrl(resultAdvisor.advisorProfileImageUrl, { width: 200 }),
                    icPrefix: formatAdvisorAgencyName(resultAdvisor.companyName, resultAdvisor.advisorIsIndependentContractor, resultAdvisor.advisorDoingBusinessAs, true),
                    id: resultAdvisor.advisorMasterEntityId,
                    interests: resultAdvisor.advisorInterestTypes || [],
                    isAcceptingNewClients: resultAdvisor.isAdvisorAcceptingNewClients,
                    isIC: resultAdvisor.advisorIsIndependentContractor,
                    isVVCertifiedHost: resultAdvisor.isVVCertifiedHost,
                    languages: resultAdvisor.advisorLanguages || [],
                    location: "", // Derived below
                    reviewsCount: resultAdvisor.totalActiveReviews || 0,
                    reviewsPercent: resultAdvisor.totalRecommendedPercent || 0,
                    sellingTravelSince: Number(resultAdvisor.advisorSellingTravelSinceYear) || null,
                    socialMedia: [] // Derived below
                };

                // Advisor Address
                if (resultAdvisor.profileAddresses && resultAdvisor.profileAddresses.length) {
                    const primaryAddress = resultAdvisor.profileAddresses.find((item) => item.typeName === "Primary");
                    if (primaryAddress) {
                        thisAdvisor.location = formatLocation(
                            primaryAddress.city,
                            primaryAddress.state,
                            primaryAddress.country
                        );
                    }
                }

                languagesCSV.value = thisAdvisor.languages.join(", ");

                // Social Media
                if (resultAdvisor.profileWebSites && resultAdvisor.profileWebSites.length) {
                    (resultAdvisor.profileWebSites).forEach((website) => {
                        if (website.typeName && website.typeName !== "Primary" && website.link) {
                            thisAdvisor.socialMedia.push({
                                siteName: website.typeName,
                                siteUrl: website.link
                            });
                        }
                    });
                }

                // Reviews
                if (thisAdvisor.reviewsCount > 0) {
                    reviewsData.value = {
                        percent: `<b>${thisAdvisor.reviewsPercent}% Recommended</b>`,
                        count: `<b>${thisAdvisor.reviewsCount} Review${getPlural(thisAdvisor.reviewsCount)}</b>`
                    };
                }


                // Places Visited map data
                const placesVisited: AdvisorVisitedPlace[] = resultAdvisor.advisorVisitedPlaces || [];

                if (placesVisited.length) {
                    placesVisited.forEach((place) => {
                        placesVisitedMapConfig.value.mapMarkers.push({
                            Id: place.pointOfInterestId,
                            PushpinLabel: "", // Always blank?
                            SequenceLabel: `${place.pointOfInterestId}`,
                            Longitude: place.longitude,
                            Latitude: place.latitude,
                            InfoWindowHtml: `<h3>${place.placeName}, ${place.countryName}</h3><div>Last Trip: ${place.lastYearVisited}<br>Number of Trips: ${place.numOfVisits}`,
                            Title: `${place.placeName}, ${place.countryName}`,
                            CountryCodeISO2: place.countryCodeISO2,
                            CountryId: place.countryId,
                            Date: `${place.lastYearVisited}`,
                            NumOfVisits: place.numOfVisits
                        });
                    });
                }

                advisor.value = thisAdvisor;

                renderMetadata({
                    title: thisAdvisor.advisorName
                });

                isReady.value = true;

                // Apply sticky subhead and highlighted subnav observers
                nextTick(function () {
                    initAdvisorSubhead();
                });

                // GA4 tracking
                const getVirtuosoAdvisorSearchIndexValue = sessionStorage.getItem(`VirtuosoAdvisorSearchIndex_${props.productId}`);

                if (getVirtuosoAdvisorSearchIndexValue) {
                    sessionStorage.removeItem(`VirtuosoAdvisorSearchIndex_${props.productId}`);
                }

                trackEvent("view_item",
                    {
                        ...((getVirtuosoAdvisorSearchIndexValue) && { index: parseInt(getVirtuosoAdvisorSearchIndexValue, 10) }), // should add index with value if session key existed
                        item_id: `${props.productId}`,
                        item_name: advisor.value.advisorName,
                        item_brand: (advisor.value.isIC) ? advisor.value.dbaName : advisor.value.companyName,
                        item_category: "Advisor",
                        item_category2: advisor.value.location,
                        item_category3: `${advisor.value.reviewsCount}`,
                        item_category4: (advisor.value.isVVCertifiedHost) ? "virtuoso voyages" : "",
                        item_variant: (advisor.value.isIC) ? "independent contractor" : "advisor",
                        affiliation: (advisor.value.isIC) ? advisor.value.companyName : ""
                    });
            } else {
                redirectOnError();
            }
        }, () => redirectOnError());
    };

    function redirectOnError(): void {
        toastError("Record not found or you don't have access to view it");
        setTimeout(function () {
            location.href = cobrandLink(`/travel/advisors`);
        }, 3000);
    }
</script>

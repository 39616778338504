<template>
    <div v-if="canViewComponent && !isLoading" class="recommended-advisors mt-3">
        <div v-if="hasAdvisors && !isCobranded()" class="slab p-2">
            <h4>Connect with a Virtuoso Travel Advisor</h4>
            <ul class="list-unstyled">
                <li v-for="(advisor, index) in recommendedAdvisors" :key="index" class="mt-2 d-flex d-md-block d-lg-flex">
                    <div v-if="advisor.advisorImageUrl" class="-headshot mb-2"><a :href="advisor.advisorUrl" @click="trackRecommendedAdvisorClick(advisor, index)"><img :src="advisor.advisorImageUrl" :alt="advisor.advisorName" class="rounded-circle d-block mx-auto" width="120" loading="lazy" /></a></div>
                    <div v-track-element-view="getRecommendedAdvisorTrackingData(advisor, index)" class="-contact-info">
                        <div><a :href="advisor.advisorUrl" class="-name" @click="trackRecommendedAdvisorClick(advisor, index)" v-html="advisor.advisorName"></a></div>
                        <div v-if="advisor.recommendedLabel" class="text--xsmall" v-html="advisor.recommendedLabel"></div>
                        <div class="mt-1">
                            <div v-if="advisor.advisorSpecialtyLabel" class="fw-bold text--small" v-html="advisor.advisorSpecialtyLabel"></div>
                            <div class="text--xsmall" v-html="advisor.agencyLocation"></div>
                            <div class="text--xsmall" v-html="advisor.agencyName"></div>
                            <advisor-contact-button-component :advisor="advisorContactButtonInfo(advisor)" :event-name="gaCategory" button-style="down-arrow" class="mt-1-half"></advisor-contact-button-component>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="mt-2"><a :href="advisorCatalogLinkFiltered">Or, find a different advisor</a></div>
        </div>
        <a v-else-if="!isNetworkUser() && !isCobranded()" class="btn btn-primary-emphasis btn-sm d-block" :href="cobrandLink('/travel/advisors/search#')">Find a Travel Advisor</a>
        <a v-else-if="!isNetworkUser() && isCobranded() && !hideCobrandedButton" class="btn btn-primary-emphasis btn-sm d-block" :href="cobrandLink('/travel/advisors/search#')">Contact Advisor to Book</a>
    </div>
</template>


<script setup lang="ts">
    import AdvisorContactButtonComponent from "components/advisor/advisor-contact-button.vue";
    import { AdvisorContactButton, RecommendedAdvisor, RecommendedAdvisorQuery } from "interfaces/advisor";
    import { ProductType } from "interfaces/enums";
    import { getRecommendedAdvisors } from "services/api/advisors";
    import { isNetworkUser } from "services/auth/user-info";
    import { extractCountryFromLocationString } from "services/helpers/destinations";
    import { generateMediaServerImageUrl } from "services/helpers/images";
    import { isEmbeddedMode } from "services/layout/environment";
    import { translateB2BFacetToConsumer } from "services/search/product-search";
    import { parseAdvisorMeidFromUrlString, translateToConsumerUrl } from "services/transformers/products";
    import { getRecommendedAdvisorTrackingData, trackRecommendedAdvisorClick } from "services/analytics";
    import { cobrandLink, getPlural, isCobranded } from "virtuoso-shared-web-ui";
    import { computed, PropType, provide, ref } from "vue";
  
    const props = defineProps({
        advisorQuery: {
            type: Object as PropType<RecommendedAdvisorQuery>,
            default: () => ({})
        },
        hideCobrandedButton: {
            type: Boolean,
            default: false
        },
        setRecommendedAdvisorIds: {
            type: Function,
            default: (): void => { /* if setRecommendedAdvisorsIds() is passed as undefined */ }
        }
    });

    // Data provided to advisor contact form
    provide("searchFilters", computed(() => ({
                    destinationSpecialties: advisorSearchquery.value.ProductLocationCountry,
                    specialties: advisorSearchquery.value.InterestType}))
    );

    const advisorCatalogLinkFiltered = ref(cobrandLink("/travel/advisors/search#"));
    const canViewComponent = ref((!isEmbeddedMode() && !isNetworkUser()));
    const gaCategory = ref((props.advisorQuery.ProductTypeName === "Cruise") ? "Sailing Detail" : `${props.advisorQuery.ProductTypeName} Detail`);
    const hasAdvisors = ref(false);
    const isLoading = ref(true);
    const recommendedAdvisors = ref([] as RecommendedAdvisor[]);
    const advisorSearchquery = ref(props.advisorQuery);

    if (canViewComponent.value) {
        // Special handling for US destination specialties, no "magic sub region" mapping (for now?)
        if (advisorSearchquery.value.ProductLocationCountry === "United States") {
            advisorSearchquery.value.ProductLocationCountry = "United States - All";
        }

        // Translation from ship cruise type to advisor interest type
        if (advisorSearchquery.value.ProductTypeName === "Cruise" && advisorSearchquery.value.InterestType) {
            advisorSearchquery.value.InterestType = `${advisorSearchquery.value.InterestType} Cruising`;
        }

        if (advisorSearchquery.value.ProductLocationCountry) {
            advisorCatalogLinkFiltered.value += `${translateB2BFacetToConsumer("advisor_specilty_destination_for_facet")}=${encodeURIComponent(advisorSearchquery.value.ProductLocationCountry)}`;
        } else if (advisorSearchquery.value.InterestType) {
            advisorCatalogLinkFiltered.value += `${translateB2BFacetToConsumer("advisor_interest_types")}=${encodeURIComponent(advisorSearchquery.value.InterestType)}`;
        }

        getAdvisors();
    }

    function advisorContactButtonInfo(advisor: RecommendedAdvisor): AdvisorContactButton {
        return {
            company: advisor.agencyName,
            companyId: advisor.companyId,
            country: advisor.country,
            email: advisor.advisorEmail,
            id: parseAdvisorMeidFromUrlString(advisor.advisorUrl),
            name: advisor.advisorName,
            phone: advisor.advisorPhone,
            productType: ProductType.ADVISORS
        };
    }

    function getAdvisors(): void {

        let firstRecommendedAdvisor: number = null;
        let lastRecommendedAdvisor: number = null;

        if (!isCobranded()) { // If it's cobranded, we only ever show either a static button or nothing

            getRecommendedAdvisors(advisorSearchquery.value)
                .then((advisorsJSON) => {

                    // Test data for local testing, comment out the getRecommendedAdvisors().then() lines & the reject below
                    // const advisorsJSON = advisorsTestData;

                    if (advisorsJSON && advisorsJSON.length > 0) {
                        const validAdvisors: RecommendedAdvisor[] = [];

                        advisorsJSON.forEach((advisor) => {
                            if (advisor.AdvisorName && advisor.ProfilePageUrl && advisor.EmailAddress && advisor.PhoneNumber && advisor.AgencyLocation && advisor.AgencyName) {
                                validAdvisors.push({
                                    advisorEmail: advisor.EmailAddress,
                                    advisorImageUrl: (advisor.ImageUrl) ? generateMediaServerImageUrl(advisor.ImageUrl, { height: 140, width: 140 }) : "",
                                    advisorName: advisor.AdvisorName,
                                    advisorPhone: advisor.PhoneNumber,
                                    advisorSpecialtyLabel: advisor.AdvisorSpecialityLabel,
                                    advisorUrl: translateToConsumerUrl(advisor.ProfilePageUrl),
                                    agencyLocation: advisor.AgencyLocation,
                                    agencyName: advisor.AgencyName,
                                    companyId: advisor.CompanyId,
                                    country: extractCountryFromLocationString(advisor.AgencyLocation),
                                    recommendedLabel: (advisor.TotalReviews && advisor.TotalReviews > 0) ? `${advisor.TotalRecommendedPercent}% Recommended (${advisor.TotalReviews} Review${getPlural(advisor.TotalReviews)})` : "",
                                    totalReviews: advisor.TotalReviews
                                });
                            }
                        });

                        if (validAdvisors.length) {
                            if (validAdvisors.length > 2) {
                                validAdvisors.length = 2; // If, for some reason, it returns more than 2 advisors, trim it down to 2
                            }
                            recommendedAdvisors.value = validAdvisors;
                            hasAdvisors.value = true;
                            // there are at max 2 advisors
                            if (recommendedAdvisors.value[0]) {
                                firstRecommendedAdvisor = parseAdvisorMeidFromUrlString(recommendedAdvisors.value[0].advisorUrl);
                            }
                            if (recommendedAdvisors.value[1]) {
                                lastRecommendedAdvisor = parseAdvisorMeidFromUrlString(recommendedAdvisors.value[1].advisorUrl);
                            }
                        }

                    } else {
                        console.log("No recommended advisors available.");
                    }
                    isLoading.value = false;
                },
                    (err: Error) => {
                        console.log("Error retrieving recommended advisors", err);
                        isLoading.value = false;
                    })
                .finally(() => {
                    props.setRecommendedAdvisorIds(firstRecommendedAdvisor, lastRecommendedAdvisor);
                });
        } else {
            if (props.hideCobrandedButton) {
                canViewComponent.value = false;
            }
            isLoading.value = false;
        }
    }
</script>

const desktopRootMargin = "-214px"; // -152 header height - 60 .-info height
const desktopRootMarginNearStickySubhead = "-294px 0px 0px 0px";
const mobileTopRootMargin = "-122px"; // -56 header height - 66 .-info height

/**
 *
 * @param {HTMLElement} advisorHeader - selector for '.advisor-header'
 * @param {HTMLElement} stickyHeader - selector for '.sticky-advisor-header'
 * */
function advisorHeaderNameObserver(advisorHeader: HTMLElement, stickyHeader: HTMLElement): void {
    const config = {
        rootMargin: `${(window.innerWidth >= 1200 ? desktopRootMargin : mobileTopRootMargin)} 0px 0px 0px`, // include nav bar height desktop & mobile
        threshold: 0
    };

    const stickyObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (!entry.isIntersecting) { // not visible in viewport
                advisorHeader.classList.add("-sticky");
                stickyHeader.classList.add("-sticky");
            } else {
                stickyHeader.classList.remove("-sticky");
                advisorHeader.classList.remove("-sticky");
            }
        });
    }, config);

    stickyObserver.observe(advisorHeader);
}



function setActiveTab(tab: HTMLElement): void {
    if (!tab.classList.contains("-active")) {
        Array.from(tab.parentElement.children).forEach((tab) => tab.classList.remove("-active"));
        tab.classList.add("-active");
    }
}



/**
 * Sets up observers with different thresholds for each section
 */
function observeSections(): void {
    const aboutTab = document.getElementById("tab-about");
    const placesTab = document.getElementById("tab-places");
    const reviewsTab = document.getElementById("tab-reviews");

    // About section
    const aboutObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (entry.intersectionRatio > 0.5) {
                    setActiveTab(aboutTab);
                } else { // This catches scrolling up when the threshold wasn't offscreen
                    if (placesTab && placesTab.classList.contains("-active")) {
                        setActiveTab(aboutTab);
                    }
                }
            }
        });
    }, { threshold: [0.5, 0.9], rootMargin: desktopRootMarginNearStickySubhead });
    aboutObserver.observe(document.getElementById("about"));

    // Places Visited section
    if (placesTab) {
        const placesObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveTab(placesTab);
                }
            });
        }, { threshold: 0.5, rootMargin: desktopRootMarginNearStickySubhead });
        placesObserver.observe(document.getElementById("places"));
    }

    // Reviews & Recommendations section
    if (reviewsTab) {
        const reviewsObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.intersectionRatio > 0.25) {
                        setActiveTab(reviewsTab);
                    } else { // This catches scrolling down when the threshold wasn't off screen
                        if (placesTab && placesTab.classList.contains("-active")) {
                            setActiveTab(reviewsTab);
                        }
                    }
                }
            });
        }, { threshold: [0.25, 0.5], rootMargin: desktopRootMarginNearStickySubhead });
        reviewsObserver.observe(document.getElementById("reviews"));
    }
}


/**
 * Runs Observers for the Advisor Detail Page
 * to implement sticky subhead and subnav highlighting
 */
export function initAdvisorSubhead(): void {
    const advisorHeader = document.querySelector<HTMLElement>(".advisor-header");
    const stickyHeader = document.querySelector<HTMLElement>(".sticky-advisor-header");
    const sections = document.querySelectorAll("section");

    if (advisorHeader && stickyHeader && sections.length) {
        // Sticky subhead
        advisorHeaderNameObserver(advisorHeader, stickyHeader);

        // Subnav highlighting
        observeSections();
    } else {
        console.warn("Page sections not found, not applying observers");
    }
}

<template>
    <div v-if="isReady" ref="product-detail" class="product-detail">
        <top-splash-component :top-splash-data="topSplashData" @promo-click="showTab('promotions', false, true)"></top-splash-component>
        <div class="container mt-4">
            <div class="product-detail-overview mb-6">
                <div>
                    <h1 v-html="tour.tourName"></h1>
                    <div class="mt-1">
                        {{ tour.tourType }}
                        <template v-if="tour.subType">
                            | {{ tour.subType }}
                        </template>
                    </div>

                    <div class="mt-2 -description">
                        <div v-if="tour.childTours.length > 1" class="mt-2">
                            Travel Dates<br />
                            <template v-if="isEmbeddedMode()">
                                Ask your advisor about available travel dates
                            </template>
                            <label v-else class="select--styled">
                                <select id="itineraryNav" class="w-100" aria-label="Select Travel Dates" @change="goToTour()">
                                    <option v-for="(item, index) in tour.childTours"
                                            :key="index"
                                            :value="item.id"
                                            :selected="item.id === tour.id"
                                            :data-url="item.url"
                                            v-html="item.travelDates"></option>
                                </select>
                            </label>
                        </div>
                        <template v-else>
                            <b>{{ tour.travelDates }}</b>
                            <br />
                        </template>
                        <b v-html="formattedLocation"></b><br />
                        <a v-if="tour.brandUrl && !isEmbeddedMode()" class="weglot-exclude" :href="tour.brandUrl">
                            <b v-html="tour.companyName"></b>
                        </a>
                        <b v-else class="weglot-exclude" v-html="tour.companyName"></b>
                    </div>

                    <div v-if="isDayTour" class="mt-2">
                        <div v-if="tour.meals">Meals: <b v-html="tour.meals"></b></div>
                        <div v-if="tour.ageAppropriateness" class="mt-1">
                            Recommended Ages: <b v-html="tour.ageAppropriateness"></b>
                        </div>
                        <div v-if="tour.activityLevel" class="mt-1">
                            Activity Level: <b v-html="tour.activityLevel"></b>
                            <b v-if="isWheelchairAccessible">&bull; Wheelchair Accessible</b>
                        </div>
                    </div>

                    <tour-experiences-component v-if="tour.experiences.length"
                                                :tour-experiences="tour.experiences"></tour-experiences-component>

                    <div v-if="tour.tourDescription"
                         class="mt-3 truncate-vertical truncate-15"
                         data-view-more-text="More details"
                         data-view-less-text="Fewer details"
                         v-html="tour.tourDescription"></div>

                    <h2 class="text--serif mt-3">At a Glance</h2>

                    <MapView v-if="routeMapConfig.routeMarkers.length" type="route" :route-map-config="routeMapConfig" />

                    <div v-if="tour.whatIsIncluded" class="mt-2">
                        <div class="mb-1"><b>Included</b></div>
                        <div v-html="tour.whatIsIncluded"></div>
                    </div>

                    <div v-if="tour.whatIsNotIncluded" class="mt-2">
                        <div class="mb-1"><b>Not Included</b></div>
                        <div v-html="tour.whatIsNotIncluded"></div>
                    </div>
                </div>
                <div class="-gallery mt-5 mt-md-0 mb-6">
                    <image-gallery-component v-if="tour.galleryImages && tour.galleryImages.length"
                                             :gallery-data="tour.galleryImages"
                                             :product-id="tour.id.toString()"
                                             :product-name="tour.tourName"
                                             :product-type="catalogType"></image-gallery-component>
                    <recommended-advisors-component v-if="!suppressRecommendedAdvisors"
                                                    :advisor-query="recommendedAdvisorQuery"
                                                    :product-type="catalogType"
                                                    :set-recommended-advisor-ids="setRecommendedAdvisorIds"></recommended-advisors-component>
                    <a v-if="isNetworkUser() && !isEmbeddedMode()"
                       id="b2blink-legacy-link-label"
                       class="btn btn-primary-emphasis btn-sm d-block mt-3"
                       :href="legacyLink"
                       @click="handleLegacyLinkClick">{{ legacyLinkLabel }}</a>
                    <button class="wl-heartable -save-this mt-3"
                            data-wl-type="tour"
                            :data-wl-id="heartableUrl"
                            :data-wl-title="tour.tourName"
                            :data-wl-list-name="wanderlistTourName"
                            aria-label="Save to Wanderlist"></button>
                </div>
            </div>
        </div>
        <div v-if="showTabBlock" id="detail-tabs"></div>
        <div v-if="showTabBlock" class="container d-none d-md-block">
            <ul ref="tabNavContainer" class="tab-nav-container">
                <li v-if="tour.itinerary.length > 1" id="tab-itinerary">
                    <button @click="showTab('itinerary')">Itinerary</button>
                </li>
                <li v-if="tour.virtuosoBenefits" id="tab-benefits">
                    <button @click="showTab('benefits')">Virtuoso Benefits</button>
                </li>
                <li v-if="tour.promotions.length" id="tab-promotions">
                    <button @click="showTab('promotions')">Promotions</button>
                </li>
                <li v-if="tour.addOnDayTours.length" id="tab-add-ons">
                    <button @click="showTab('add-ons')">Add-ons</button>
                </li>
            </ul>
        </div>
        <div v-if="showTabBlock" class="slab -tab-slab">
            <div class="container">
                <ul class="tab-content">
                    <li v-if="tour.itinerary.length > 1" id="tc-itinerary">
                        <button class="tab-nav" @click="showTab('itinerary')">
                            Itinerary
                        </button>
                        <div class="-container">
                            <h4 class="tab-title">Itinerary</h4>
                            <product-itinerary-component :itinerary="tour.itinerary"
                                                         :product-type="catalogType"
                                                         :is-cruise-tour="false"></product-itinerary-component>
                        </div>
                    </li>
                    <li v-if="tour.virtuosoBenefits" id="tc-benefits">
                        <button class="tab-nav" @click="showTab('benefits')">
                            Virtuoso Benefits
                        </button>
                        <div class="-container">
                            <h4 class="tab-title">Virtuoso Benefits</h4>
                            <h2 class="text--serif">Exclusively for Virtuoso Guests</h2>
                            <div class="mt-2" v-html="tour.virtuosoBenefits"></div>
                        </div>
                    </li>
                    <li v-if="tour.promotions.length" id="tc-promotions">
                        <button class="tab-nav" @click="showTab('promotions')">
                            Promotions
                        </button>
                        <div class="-container">
                            <h4 class="-title">Promotions</h4>
                            <promotions-component :product-type="catalogType" :promotions="tour.promotions"></promotions-component>
                        </div>
                    </li>
                    <li v-if="tour.addOnDayTours.length" id="tc-add-ons">
                        <button class="tab-nav" @click="showTab('add-ons')">Add-ons</button>
                        <div class="-container">
                            <h4 class="tab-title">Add-ons</h4>
                            <div class="mb-4">
                                <h2 class="text--serif">Excursions</h2>
                                <div v-for="(segment, segmentIndex) in tour.addOnDayTours" :key="segmentIndex" class="add-ons-box mt-2">
                                    <div class="-title-row">
                                        <div class="-location">
                                            {{ segment.addOnDate }}
                                            <span class="d-none d-md-inline">&mdash;</span><span class="d-md-none"><br /></span>
                                            {{ segment.addOnLocation }}
                                        </div>
                                        <div class="-toggle">
                                            <button @click.prevent.stop="toggleAddOn(segmentIndex)">
                                                <i :id="'add-on-toggle-shore-excursion-' + segmentIndex" class="icon-plus-circle-ut"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div :id="'add-on-container-shore-excursion-' + segmentIndex" class="add-on-container">
                                        <add-on-tours-component v-for="(pkg, pkgIndex) in segment.addOns" :key="pkgIndex" :add-on="pkg"></add-on-tours-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <LogoSplash v-else />
</template>

<script setup lang="ts">
    import AddOnToursComponent from "components/products/details/add-on-tours.vue";
    import ProductItineraryComponent from "components/products/details/product-itinerary.vue";
    import PromotionsComponent from "components/products/details/product-promotions.vue";
    import TourExperiencesComponent from "components/products/details/tour-experiences.vue";
    import RecommendedAdvisorsComponent from "components/advisor/recommended-advisors.vue";
    import ImageGalleryComponent from "components/shared/image-gallery.vue";
    import LogoSplash from "components/shared/logo-splash.vue";
    import MapView from "components/shared/map-view.vue";
    import TopSplashComponent from "components/shared/top-splash.vue";
    import { tourBrandSupplierTypesRestricted } from "config/collections";
    import { ProductDetailsTourResponse } from "interfaces/responses/product-detail-responses";
    import { RecommendedAdvisorQuery } from "interfaces/advisor";
    import { ProductType, SupplierType } from "interfaces/enums";
    import { GalleryItem } from "interfaces/image";
    import { ProductDetailsTour, ProductTopSplash, Promotion } from "interfaces/product";
    import { AddOnDayTours, DayTourPackage } from "interfaces/tour";
    import { getProductDetails } from "services/api/products";
    import { isNetworkUser, isSupplier } from "services/auth/user-info";
    import { sanitizeUserGeneratedContent, toggleSlideWithFade } from "services/helpers/html";
    import { hydrateImageGallery } from "services/helpers/images";
    import { toastError } from "services/helpers/toasts";
    import { applyVerticalTruncation } from "services/helpers/truncation";
    import { isEmbeddedMode, isMobileScreenWidth } from "services/layout/environment";
    import { setB2BDesktopCookie } from "services/layout/metadata";
    import { trackEvent } from "services/analytics";
    import { enableHearts } from "services/wanderlist";
    import { capitalizeFirst, cobrandLink, parseURLParameters, slugify } from "virtuoso-shared-web-ui";
    import { nextTick, ref, useTemplateRef } from "vue";

    let topPromoName = ""; // Referenced by GA

    const qsParams = parseURLParameters();
    const props = defineProps({
        productId: {
            type: Number,
            default: undefined
        },
        suppressRecommendedAdvisors: {
            type: Boolean,
            default: false
        }
    });

    const catalogType = ProductType.TOURS;
    const heartableUrl = document.querySelector("link[rel='canonical']")?.getAttribute("href");
    const isDayTour = ref(false);
    const isReady = ref(false);
    const isWheelchairAccessible = ref(false);
    const formattedLocation = ref("");
    const legacyLink = cobrandLink(`/tours/${props.productId}`);
    const legacyLinkLabel = (isSupplier()) ? "For Partners: Update Your Profile" : "For Advisors: Advanced Results";
    const productDetailRef = useTemplateRef("product-detail");
    const recommendedAdvisorQuery = ref<RecommendedAdvisorQuery>({} as RecommendedAdvisorQuery);
    const routeMapConfig = ref({ routeMarkers: [] });
    const showTabBlock = ref(false);
    const topSplashData = ref<ProductTopSplash>({} as ProductTopSplash);
    const tour = ref<ProductDetailsTour>({} as ProductDetailsTour);
    const wanderlistTourName = ref("");
    const tabNavContainerRef = useTemplateRef("tabNavContainer");

    function goToTour(): void {
        const elSelect = document.getElementById("itineraryNav") as HTMLSelectElement;
        const newId = parseInt(elSelect.value, 10);
        if (newId && newId !== props.productId) {
            window.location.href = elSelect.options[elSelect.selectedIndex].dataset.url;
        }
    }

    function handleLegacyLinkClick(): void {
        setB2BDesktopCookie();
        trackEvent("legacy_page_click", {
            affiliation: `${tour.value.companyId}`,
            item_id: `${props.productId}`,
            item_name: tour.value.companyName,
            item_category: capitalizeFirst(ProductType.TOURS.slice(0,-1))
        });
    }

    function loadTour(): void {
        getProductDetails(catalogType, props.productId).then((resultTour: ProductDetailsTourResponse) => {
            if (resultTour && resultTour.companyName) {
                const brandUrl = (tourBrandSupplierTypesRestricted.includes(resultTour.companyType as SupplierType)) ? cobrandLink(`/travel/luxury-tours/tour-operators/${resultTour.companyId}/${slugify(resultTour.companyName)}`) : "";

                const thisTour: ProductDetailsTour = {
                    addOnDayTours: [],
                    companyId: resultTour.companyId || 0, // Only used for retrieving reviews
                    brandUrl: brandUrl,
                    childTours: [],
                    experiences: resultTour.tourExperiences || [],
                    featuredImageUrl: "https://virtuoso-prod.dotcms.cloud/images/image-not-available-results-266x200.png", // fallback, replaced later
                    id: props.productId,
                    itinerary: [],
                    companyName: resultTour.companyName,
                    promotions: [],
                    activityLevel: resultTour.tourActivityLevels, // Day tours only?
                    ageAppropriateness: resultTour.tourAgeAppropriateness,
                    meals: resultTour.meals ? resultTour.meals.join(", ") : "", // Day tours only?
                    supplierType: resultTour.companyType as SupplierType,
                    tourCategory: resultTour.tourCategory,
                    tourDescription: (resultTour.tourDescription) ? sanitizeUserGeneratedContent(resultTour.tourDescription) : "",
                    tourLength: resultTour.tourLength,
                    tourName: `${resultTour.tourName} (${(resultTour.tourLength.trim()).replace(" ", "&nbsp;").replace("days", "Days").replace("hours", "Hours")})`,
                    tourNameWithoutLength: resultTour.tourName,
                    tourType: resultTour.tourType,
                    travelDates: resultTour.travelDates,
                    virtuosoBenefits: resultTour.virtuosoBenefitsDesc || "",
                    whatIsIncluded: "",
                    whatIsNotIncluded: ""
                };

                isDayTour.value = (thisTour.tourType === "Day Tour");

                isWheelchairAccessible.value = thisTour.experiences.includes("Wheelchair Accessible");
                formattedLocation.value = resultTour.embarkation;

                if (resultTour.disembarkation && (!isDayTour.value || resultTour.embarkation !== resultTour.disembarkation)) {
                    formattedLocation.value += ` to ${resultTour.disembarkation}`;
                }


                // What's (Not) Included
                if (resultTour.whatIsIncludedItems && resultTour.whatIsIncludedItems.length) {
                    thisTour.whatIsIncluded = (resultTour.whatIsIncludedItems.length > 1)
                        ? `<ul>${resultTour.whatIsIncludedItems.map((item) => `<li>${item}</li>`).join("")}</ul>`
                        : sanitizeUserGeneratedContent(resultTour.whatIsIncludedItems[0]);
                }

                if (resultTour.whatIsNotIncludedItems && resultTour.whatIsNotIncludedItems.length) {
                    thisTour.whatIsNotIncluded = (resultTour.whatIsNotIncludedItems.length > 1)
                        ? `<ul>${resultTour.whatIsNotIncludedItems.map((item) => `<li>${item}</li>`).join("")}</ul>`
                        : sanitizeUserGeneratedContent(resultTour.whatIsNotIncludedItems[0]);
                }

                // Images -- first image is the featured image
                let galleryImages: GalleryItem[] = [];
                if (resultTour.imageLibraryItems && resultTour.imageLibraryItems.length) {
                    galleryImages = hydrateImageGallery(resultTour.imageLibraryItems);
                    thisTour.featuredImageUrl = galleryImages[0].image;
                    thisTour.featuredImageCaption = galleryImages[0].description;
                }
                thisTour.galleryImages = galleryImages;

                // Featured Video
                if (resultTour.supplierVideos && resultTour.supplierVideos.length) {
                    const featuredVideo = resultTour.supplierVideos.find((video) => video.isFeaturedVideo);
                    if (featuredVideo) {
                        thisTour.featuredVideoCaption = featuredVideo.title;
                        thisTour.featuredVideoUrl = featuredVideo.webContentURL;
                    }
                }


                // Child tours, if populated
                if (resultTour.childProducts && resultTour.childProducts.length && resultTour.childProducts.length > 1) {
                    resultTour.childProducts.forEach((childTour) => {
                        if (childTour.masterEntityId) { // && childTour.travelDates
                            thisTour.childTours.push({
                                id: childTour.masterEntityId,
                                travelDates: `${childTour.travelStartDate} - ${childTour.travelEndDate}`, // childTour.travelDates,
                                url: cobrandLink(`/travel/luxury-tours/${childTour.masterEntityId}/${slugify(resultTour.tourName)}`)
                            });
                        }
                    });
                }

                // Add "Select Dates" option at the beginning if this is a master tour
                if (thisTour.childTours.length && !thisTour.childTours.some((childTour) => childTour.id === thisTour.id )) {
                    thisTour.childTours.unshift({
                        id: null,
                        travelDates: "Select Dates",
                        url: ""
                    });
                }


                // Itinerary
                if (resultTour.itineraryPoints && resultTour.itineraryPoints.length) {
                    let countryFound = 0;
                    let previousDay = 0;
                    let currentDay = 0;
                    let previousDataDay = 0; // The day number from the data -- tours sometimes have insane, inaccurate day numbers (e.g. 138, 139, 1)
                    resultTour.itineraryPoints.forEach((it) => {
                        if (it.dayOfTour > (previousDay + 1) || it.dayOfTour < previousDay) { // Bad data -- "this" day is more than one day ahead or behind of the last day
                            currentDay = (it.dayOfTour === previousDataDay) ? currentDay : currentDay + 1;
                        } else {
                            currentDay = it.dayOfTour;
                        }

                        if (currentDay !== previousDay) {
                            thisTour.itinerary.push({
                                country: it.country,
                                dateFormatted: (thisTour.tourType === "Multi-Day Tour" && thisTour.tourCategory === "NoSpecificDeparture") ? "" : it.segmentDate,
                                description: "",
                                hotelName: it.hotelName,
                                itineraryDay: currentDay,
                                meals: it.mealsFormatted || "None",
                                stops: []
                            });
                            previousDay = currentDay;
                        }

                        if (thisTour.itinerary[currentDay - 1]) { // Sanity check, just in case
                            thisTour.itinerary[currentDay - 1].stops.push({
                                latitude: it.portLatitude || 0,
                                location: it.portName || "N/A",
                                longitude: it.portLongitude || 0
                            });
                        }

                        if (it.portLatitude && it.portLongitude && it.portLatitude !== 0 && it.portLongitude !== 0) {
                            routeMapConfig.value.routeMarkers.push({
                                masterEntityId: 0,
                                pinCoordinate: {
                                    latitude: it.portLatitude,
                                    longitude: it.portLongitude
                                },
                                pinType: 0,
                                locationPath: "",
                                title: it.portName
                            });
                        }

                        if (it.stopDescription && it.stopDescription !== "") {
                            if (thisTour.itinerary[currentDay - 1]) { // Sanity check, just in case
                                thisTour.itinerary[currentDay - 1].description = sanitizeUserGeneratedContent(it.stopDescription);
                            }
                        }
                        previousDataDay = it.dayOfTour;

                        // Wanderlist, Default List Name
                        if (it.country && countryFound === 0) {
                            wanderlistTourName.value = it.country;
                            countryFound++;
                        } else if (countryFound === 1) { // -- Multi-Day Tour
                            if (it.country && it.country !== wanderlistTourName.value) {
                                wanderlistTourName.value += ` and ${it.country}`;
                                countryFound++;
                            }
                        }
                    });
                }

                // Add Ons -- Day tours
                if (resultTour.addOns && resultTour.addOns.addOnDayTours && resultTour.addOns.addOnDayTours.length) {
                    const dayTours: AddOnDayTours[] = [];
                    resultTour.addOns.addOnDayTours.forEach((segment, segmentIndex: number) => {
                        if (segment.dayTours && segment.dayTours.length) {
                            dayTours.push({ // First add the date+location segment
                                addOnDate: segment.addOnDate,
                                addOnLocation: segment.addOnLocation,
                                addOns: []
                            });

                            segment.dayTours.forEach((pkg: DayTourPackage) => { // Then loop through the tours for that segment and add them
                                dayTours[segmentIndex].addOns.push({
                                    activityLevel: pkg.activityLevelList || "",
                                    ageAppropriateness: pkg.ageAppropriatenessList || "",
                                    companyName: pkg.supplierName,
                                    description: sanitizeUserGeneratedContent(pkg.dayTourDescription),
                                    eventDate: pkg.dayTourDateFormatted,
                                    eventLength: pkg.dayTourLength,
                                    experiences: (pkg.bulletedActivityTypes) ? pkg.bulletedActivityTypes : "",
                                    id: parseInt(pkg.dayTourMasterEntityID, 10),
                                    location: pkg.locationFormatted,
                                    meals: pkg.mealsFormatted || "",
                                    name: pkg.dayTourName,
                                    thumbnailImageUrl: pkg.thumbnailImageUrl || "",
                                    url: cobrandLink(`/packages/${pkg.dayTourMasterEntityID}/${slugify(pkg.dayTourName)}`),
                                    virtuosoHotel: ""
                                });
                            });
                        }
                    });
                    thisTour.addOnDayTours = dayTours;
                }


                // Promotions -- Virtuoso Exclusive Promotions added first
                if (resultTour.promotions && resultTour.promotions.length) {
                    const visiblePromotions: Promotion[] = [];
                    resultTour.promotions.forEach((promo) => {
                        if (!promo.isAdvisorIncentive && !promo.incentiveTypeCode) {
                            visiblePromotions.push({
                                description: promo.description || "",
                                endDateMS: (promo.travelEndDate) ? new Date(promo.travelEndDate).getTime() : 0,
                                formattedTravelDates: promo.formattedTravelDates || "",
                                isExclusive: false,
                                name: promo.promotionName || "",
                                promotionId: promo.masterEntityId,
                                startDateMS: (promo.travelStartDate) ? new Date(promo.travelStartDate).getTime() : 0,
                                url: (promo.masterEntityId) ? cobrandLink(`/promotions/${promo.masterEntityId}`) : ""
                            });
                        }
                    });

                    thisTour.promotions = visiblePromotions;
                    topPromoName = (thisTour.promotions.length) ? thisTour.promotions[0].name : "";
                }

                tour.value = thisTour;

                topSplashData.value = {
                    companyName: thisTour.companyName,
                    featuredImageCaption: thisTour.featuredImageCaption,
                    featuredImageUrl: thisTour.featuredImageUrl,
                    featuredVideoUrl: thisTour.featuredVideoUrl,
                    productName: thisTour.tourName,
                    productType: ProductType.TOURS,
                    ...(thisTour.promotions[0] && { promotion: thisTour.promotions[0] }),
                    wanderlistId: heartableUrl,
                    wanderlistName: wanderlistTourName.value
                } as ProductTopSplash;



                // Recommended Advisors -- country of second day for multi-day tours, first day otherwise
                let recommendedAdvisorCountry = "";
                if (thisTour.itinerary.length) {
                    if (isDayTour.value) {
                        recommendedAdvisorCountry = thisTour.itinerary[0].country;
                    } else {
                        recommendedAdvisorCountry = (thisTour.itinerary.length > 1) ? thisTour.itinerary[1].country : thisTour.itinerary[0].country;
                    }
                }

                recommendedAdvisorQuery.value = {
                    Id: thisTour.id,
                    InterestType: "",
                    ProductLocationCountry: recommendedAdvisorCountry,
                    ProductPois: "",
                    ProductTypeName: "Tour"
                } as RecommendedAdvisorQuery;

                isReady.value = true;                

                nextTick(() => {
                    // Onload scroll to tab support
                    showThenJumpToTab();
                    if (thisTour.tourDescription) {
                        applyVerticalTruncation(document.querySelectorAll(".product-detail-overview .truncate-vertical"));
                    }

                    enableHearts(productDetailRef.value);
                });

            } else {
                redirectOnError();
            }
        }, () => redirectOnError());
    }

    function redirectOnError(): void {
        toastError("Error retrieving data");
        setTimeout(() => {
            location.href = cobrandLink(`/travel/luxury-tours`);
        }, 3000);
    }

    function scrollToTabResponsive(tabName = ""): void {
        if (isMobileScreenWidth()) {
            return (tabName) ? document.getElementById(`tc-${tabName}`)?.scrollIntoView()
                : document.querySelector(".tab-content .-active")?.scrollIntoView({ block: "start" });
        }
        tabNavContainerRef.value.scrollIntoView();
    }

    function setRecommendedAdvisorIds(advisor1MEID?: number, advisor2MEID?: number): void {

        const getVirtuosoTourSearchIndexValue = sessionStorage.getItem(`VirtuosoTourSearchIndex_${props.productId}`);

        if (getVirtuosoTourSearchIndexValue) {
            sessionStorage.removeItem(`VirtuosoTourSearchIndex_${props.productId}`);
        }

        trackEvent("view_item", {
            ...((getVirtuosoTourSearchIndexValue) && { index: parseInt(getVirtuosoTourSearchIndexValue, 10) }), // should add index with value if session key existed
            item_id: `${props.productId}`,
            item_name: tour.value.tourNameWithoutLength,
            coupon: (tour.value.promotions.length >= 1) ? "Promotion Available" : "",
            item_category: "Tour",
            item_category2: (tour.value.activityLevel) ? `${tour.value.activityLevel}` : "",
            item_category3: tour.value.tourLength,
            item_category4: (advisor1MEID) ? `${advisor1MEID}` : "",
            item_category5: (advisor2MEID) ? `${advisor2MEID}` : "",
            item_variant: `${tour.value.galleryImages.length} photos`
        });
    };

    function showTab(tabName: string, preventJump = false, fromTopLink = false): void {
        document.querySelectorAll(".tab-nav-container .-active, .tab-content .-active").forEach((el) => el.classList.remove("-active"));
        document.querySelectorAll(`#tab-${tabName}, #tc-${tabName}`).forEach((el) => el.classList.add("-active"));

        if (!preventJump) {
            scrollToTabResponsive(tabName);
        }

        if (fromTopLink) {

            trackEvent("view_promotion", {
                item_id: `${tour.value.promotions[0].promotionId}`,
                item_name: topPromoName,
                item_category: "Promotion",
                item_variant: "Tour",
                item_category2: "Promo Visibility: All",
                affiliation: `${props.productId}`
            });
        }
    }

    function showThenJumpToTab(): void {
        // Which bottom tab should be selected by default
        const defaultTabName =
            (tour.value.itinerary.length > 1) ? "itinerary" :
            (tour.value.virtuosoBenefits) ? "benefits" :
            (tour.value.promotions.length) ? "promotions" :
            (tour.value.addOnDayTours.length) ? "add-ons" : "";

        const qsTabLabel = qsParams["tab"];

        showTabBlock.value = (defaultTabName.length > 0);

        nextTick(() => { // Tab Show then Scroll
            // Show
            if (defaultTabName) { // first tab
                showTab(defaultTabName, true);
            }
            if (qsTabLabel && document.getElementById(`tc-${qsTabLabel}`)) { // check qsParam tab exists
                showTab(qsTabLabel, true);
            }
            // Show Promo Manual Tab
            if (qsParams.promotions === "1") { // If the manually added ?promotions=1 query string variable is present, scroll to the promotions section on load
                const qsPromoTabName = (tour.value.promotions.length) ? "promotions" : defaultTabName;
                showTab(qsPromoTabName, true);
            }
            // Default Scroll
            if (("tab" in qsParams) || ("promotions" in qsParams)) {
                scrollToTabResponsive();
            }
        });

    }

    function toggleAddOn(id: number): void {
        const btn = document.getElementById(`add-on-toggle-shore-excursion-${id}`);
        const container = document.getElementById(`add-on-container-shore-excursion-${id}`);


        btn.classList.toggle("icon-plus-circle-ut");
        btn.classList.toggle("icon-minus-circle-ut");
        toggleSlideWithFade(container, 500);  
    }

    loadTour();

</script>
